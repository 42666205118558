import { React, useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import config from "./config"
import API from './api/api'
import Formatter from './api/formatter'
import Map from './Components/Map'
import {Helmet} from "react-helmet";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useWindowDimensions} from 'react-native';
import {Container, Row, Col, Grid} from 'react-bootstrap';

import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from 'chart.js';
import {Scatter} from 'react-chartjs-2';

import {

    ShoppingBagIcon,
    BuildingOffice2Icon,
    HomeModernIcon,
    BeakerIcon,
    BoltIcon,
    TruckIcon,
    UserGroupIcon,
    Cog8ToothIcon,
    AcademicCapIcon,
    BanknotesIcon,
    FilmIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'

import PageNext from './media/images/page-next-100.png'
import PagePrev from './media/images/page-prev-100.png'
import Logo     from './media/images/logo.jpg'
import SideImage from './media/images/skyscraper-g6eaf8fbe8_1920.jpg'

const Overview = () =>  {
    console.log("Portfolio")
    const navigate = useNavigate()
    const location = useLocation()

    const [currentInvestment, setCurrentInvestments] = useState(null)
    const [showModal, setShowModal]       = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [investments, setInvestments]   = useState([])
    useEffect(() => {
        console.log("Portfolio - useEffect")
    }, {});


    const onChange = (val) => {
    }

    const onChangeAny = (val, attr) => {
    }

    const {screenHeight, screenWidth, scale, fontScale} = useWindowDimensions();
    
    console.log("Portfolio - RENDER")

    return (
        <>
		<Helmet>
		  <title>Sikkema Investments</title>
		</Helmet>
	    <div className="container mx-auto lg:px-4 py-4 min-h-screen flex flex-col md:items-center md:justify-center">
	    <Container>
	      <Row>
	        <Col className="md-6">
		    <div className="text-center content-header mb-6">
		        Sikkema Investments for financial modelling and risk assessment
		    </div>
		    <div className="text-center content-item mb-6">
	    We build financial models for cash flows, loan portfolios, asset backed securities and insolvency risk.
		    </div>
		    <div className="text-center content-item mb-6">
		        You can access a demo <a href="https://adcm.sikkemasoftware.nl/Overview">here</a>
	            </div>
	        </Col>
	        <Col className="md-6">
		    <div className="content-right mb-6">
	    <img src={SideImage} height={screenHeight-50}/>
	            </div>
	        </Col>
	      </Row>
	    </Container>
  	    </div>
        </>
	)

}


export default Overview
