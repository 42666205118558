
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom'
import { useNavigate, useLocation, useParams } from "react-router-dom"
import config from "./config"

import {Helmet} from "react-helmet";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Container, Row, Col, Grid} from 'react-bootstrap';
import {useWindowDimensions} from 'react-native';

import {
    XMarkIcon,
    CheckIcon,
} from '@heroicons/react/24/outline'

import PageNext from './media/images/page-next-100.png'
import PagePrev from './media/images/page-prev-100.png'
import Logo     from './media/images/logo.png'

import projects from "./projects.json"

const Portfolio = () =>  {
    console.log("PORTFOLIO")
    const navigate = useNavigate()
    const location = useLocation()
    const { id }   = useParams();
    console.log("ID", id)
    const [current, setCurrent]           = useState((id) ?  projects[id] : null)
    const [showModal, setShowModal]       = useState((id) ? true : false)
    const [errorMessage, setErrorMessage] = useState("")

    const {screenHeight, screenWidth, scale, fontScale} = useWindowDimensions();
    
    useEffect(() => {
        console.log("Overview - useEffect")
    }, {});


    const onChange = (val) => {
    }

    const onChangeAny = (val, attr) => {
    }

    const onKeyDown = (event) => {
        if (event.keyCode === 27) {
            closeModal();
        }
    };

    const onClickOutside = (event) => {
        //if (modal && modal.contains(event.target)) return;
        closeModal();
    };

    const toggleScrollLock = () => {
        document.querySelector('html').classList.toggle('scroll-lock');
    };


    const openModal = (project) => {
        setCurrent(project)
        setShowModal(true)
    };

    const closeModal = () => {
        setShowModal(false)
    };

    const renderModal = () => {
        console.log("Overview - RenderModal", current)
        if (current === null) {
            return (<div></div>)
        }
        console.log("Overview - RenderModal")
        const urlParams   = "/"+current.id

        return (
    	    <Modal
                show={showModal}
                onHide={closeModal}
            centered
	    className="modal"
		>
            <Modal.Header className="modal-header" closeButton>
                  <Modal.Title className="">{current.title}</Modal.Title>
            </Modal.Header>

	    <Modal.Body className="modal-body">
	      <Container className="modal-container">
              <Row>
	        <Col md={12} className="">
		<div className="modal-image">
		<img src={current.featured_img} height={Math.round(screenHeight*0.25)}/>
		{current.featured_caption}

		</div>
    		</Col>
	      </Row>
		{current.content.map((item) => {
		    if (typeof item === 'string' || item instanceof String) {
			return (
				<Row>
				<Col md={12} className="">
				<div className="modal-content">
				{item}
			    </div>
				</Col>
				</Row>
		    )
		    } else if (item.type !== undefined && item.type === "img") {
			return (
			    <Row>
				<Col md={12} className="">
			      <div className="modal-image">
			      <img src={item.href}/>
			      {item.caption}
			      </div>
			    </Col>
			    </Row>
			)
		    } else if (item.type !== undefined && item.type === "link") {
			return (
			    <Row>
				<Col md={12} className="">
			      <div className="modal-content">
				<a href={item.href}>{item.text}</a>
			      </div>
			    </Col>
			    </Row>
			)
			} else {
			    console.error("Portfolio.js - Invalid type", item)
			}
		    
		})}
  	      </Container>
            </Modal.Body>
            <Modal.Footer>
	      <Container className="">
                <Row><Col md="12">
                </Col></Row>
	      </Container>
            </Modal.Footer>
            </Modal>
        )
    }

    const renderProject = (index, project) => {
        const activeClass = "w-5 h-9 text-black-900"
	console.log(index, index % 2)
	console.log("ODD", project.featured_img)
	return (
	    	<Row className="content-box">
		<Col md={3} className="content-header">
		<div className="content-image">
		<img src={project.featured_img} height={Math.round(screenHeight*0.25)}/>
		
		{project.featured_caption}
		</div>
                </Col>
                <Col md={9} className="content-item">
		<div className="content-header">
                    {project.title}
	        </div>
		<div className="content-item clickable" onClick={(event)=>openModal(project)}>
                    {project.content[0]}
	        </div>
		<div className="content-item">
                    <button className="modal-button clickable" onClick={(event)=>openModal(project)}><div className="modal-content">Read More...</div></button>
	        </div>

                </Col>
		</Row>
	)
    }

    return (
        <>
		<Helmet>
		  <title>Example Projects</title>
		</Helmet>
            <div section className="content-container">
	    <Container>
           {projects.map((project, index) => renderProject(index, project))}
	    </Container>
        </div>
        <div className="content-container">
           {renderModal()}
        </div>
        </>
	)

}



export default Portfolio



