import { ThemeProvider } from 'styled-components'

import React from 'react';

//import React, { Component } from 'react';
//import { Provider, Observer  } from 'mobx-react-lite'

import 'bootstrap/dist/css/bootstrap.min.css';

import colors from 'tailwindcss/colors'
import {
  BrowserRouter as BrowserRouter,
  Routes,
  Navigate,
  Route,
} from "react-router-dom";

import Header from './Header'
import Footer from './Footer'

import Overview from './Overview'
import About from './About'
import Contact from './Contact'
import Blog from './Blog'
import Portfolio from './Portfolio'
import config from './config'

import './App.scss'
import './Modal.scss'


const App = () => {
    return (
        <>
           <BrowserRouter>
                <Header/>
                <Routes>
                    <Route path={config.route.base} element={<Overview/>}/>
                    <Route path={config.route.overview} element={<Overview/>}/>
                    <Route path={config.route.portfolio+"/:id"} element={<Portfolio/>}/>
                    <Route path={config.route.portfolio+"_:id"} element={<Portfolio/>}/>
                    <Route path={config.route.portfolio} element={<Portfolio/>}/>
                    <Route path={config.route.blog+"/:id"} element={<Blog/>}/>
                    <Route path={config.route.blog+"_:id"} element={<Blog/>}/>
                    <Route path={config.route.blog} element={<Blog/>}/>
                    <Route path={config.route.about} element={<About/>}/>
                    <Route path={config.route.contact} element={<Contact/>}/>
                </Routes>
                <Footer/>
            </BrowserRouter>
        </>

    )

}

export default App
