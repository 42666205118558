
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom'
import { useNavigate, useLocation, useParams } from "react-router-dom"
import {useWindowDimensions} from 'react-native';

import {Helmet} from "react-helmet";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Container, Row, Col, Grid} from 'react-bootstrap';

import Router from "./api/router"
import config from "./config"


import PageNext from './media/images/page-next-100.png'
import PagePrev from './media/images/page-prev-100.png'
import Logo     from './media/images/logo.png'

import blogs from "./blogs.json"

const Blog = () =>  {
    const navigate = useNavigate()
    const location = useLocation()
    const { id }   = useParams();
    const router   = new Router(navigate, location)
    const {screenHeight, screenWidth, scale, fontScale} = useWindowDimensions();
    console.log(id)

    const [current, setCurrent]           = useState((id) ?  blogs[id] : null)
    const [errorMessage, setErrorMessage] = useState("")

    useEffect(() => {
        console.log("Overview - useEffect")
    }, {});

    const renderBlogs = (index, blog) => {
        const activeClass = "w-5 h-9 text-black-900"
        return (
	    <Row className="">
		<Col md={2} className="">
              <div className="content-item">
                {blog.author}
	      </div>
              <div className="content-item">
                {blog.date}
	      </div>
	      <div className="content-image">
		<img src={blog.featured_img} height={Math.round(screenHeight*0.25)}/>
		{blog.featured_caption}
	      </div>
                </Col>
		<Col md={10} className="">
		<div className="content-header clickable" onClick={() => {navigate(config.route.blog+"/"+index); navigate(0)}}>
		{blog.title}
	        </div>
		<div className="content-item clickable" onClick={() => {navigate(config.route.blog+"/"+index); navigate(0)}}>
		{blog.content[0]}
	        </div>
		<div className="content-item">
                <button className="modal-button clickable" onClick={() => {navigate(config.route.blog+"/"+index); navigate(0)}}><div className="content-item">Read More...</div></button>
	        </div>

            </Col>
            </Row>
	)
    }
    
    const renderBlog = () => {
	if (current === null) {
	    return (<></>)
	}
	const blog = current
	
        const activeClass = "w-5 h-9 text-black-900"
        return (
          <Row className="content-box">
	      <Col md={2} className="">
		<div  className="clickable" onClick={() => {navigate(config.route.blog); navigate(0)}}>
                Back
	      </div>
	      <div  className="">
                {blog.author}
	      </div>
	      <div  className="">
                {blog.date}
	      </div>
            </Col>
  	    <Col md={10} className="">
	      <Row className="">
		<Col md={12} className="">
		  <div className="content-header">
		    {blog.title}
	          </div>
                </Col>
	      </Row>
	      <Row className="">
		<Col md={10} className="">
		  <div className="content-image">
		<img src={blog.featured_img} height={Math.round(screenHeight*0.25)}/>
		{blog.featured_caption}
	          </div>
                </Col>
	      </Row>
	     {current.content.map((item) => {
		 if (typeof item === 'string' || item instanceof String) {
			return (
			    <Row>
	  			<Col md={10} className="content-item">
			    <div className="content-item">
			    {item}
			    </div>
			    </Col>
				</Row>
			)
		    } else if (item.type !== undefined && item.type === "img") {
			return (
			    <Row>
			      <Col md={10} className="content-item">
			      <div className="content-image">
				<img src={item.href}/>
			      {item.caption}
			      </div>
			    </Col>
			    </Row>
			)
		    } else if (item.type !== undefined && item.type === "link") {
			return (
			    <Row>
				<Col md={10} className="content-item">
			      <div className="content-item">
				<a href={item.href}>{item.text}</a>
			      </div>
			    </Col>
			    </Row>
			)
		    } else {
			    console.error("Blog.js - Invalid type", item)
		    }
	     })}							  
	    </Col>
	  </Row>
	)
    }

    console.log(blogs)
    
    return (
        <>
	<Helmet>
	  <title>Example Blogs</title>
	</Helmet>
	    
        <div className="content-container">
	    <Container>
           {renderBlog()}
	    </Container>
        </div>
            <div section className="content-container">
	    <Container>
            {(current === null) ? blogs.map((blog, index) => renderBlogs(index, blog)) : <></>}
	    </Container>
        </div>
        </>
	)

}



export default Blog



